import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from '@keystone-angular/core';
import { pickBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { StockMovementTypeFilter } from './catalog/models/domain/stock-movement-type-filter';
import { Filters } from './models/filters';
import { Inventory } from './models/inventory';
import { SizeRange } from './models/size-range';

export interface GetMetadataRequest {
  search?: string;
  skip?: number;
  take?: number;
  filterSingleProduct?: boolean;
  ids?: string[];
}

export interface GetProductsRequest {
  itemsPerPage: number;
  page: number;
  search: string;
}

export interface GetProductHistoryRequest {
  page: number;
  itemsPerPage: number;
  filters: Filters;
}

export interface GetProductReservationsRequest {
  page: number;
  itemsPerPage: number;
  filters: Filters;
}

export interface GetProductsResponse {
  items: any[];
  totalCount: number;
}

export interface GetPricelistsResponse {
  recommendedPricelistId?: number;
  recommendedPricelistName?: string;
  salePricelistId?: number;
  salePricelistName?: string;
}

export interface ProductFields {
  brand: any[];
  color: any[];
  gender: string[];
  productGroup: any[];
  season: any[];
  sizeSystem: any[];
}

@Injectable({
  providedIn: 'root'
})
export class ProductDataService {
  // TODO: Should we split this dataservice into several?
  private productIdentifierUrl = 'pid';
  private productSearchUrl = 'v2/productsearch';
  private productUrl = 'product';
  private productV2Url = 'v2/product';
  private currencyUrl = 'currency';

  constructor(private dataService: DataService) { }

  addProduct(product: any): Observable<any> {
    return this.dataService.post(this.productUrl, product);
  }

  deleteProduct(productId: string): Observable<any> {
    return this.dataService.delete(`${this.productUrl}/${productId}`);
  }

  getBrands(opts: GetMetadataRequest, blockingRequest: boolean): Observable<any> {
    return this.dataService.get(`${this.productUrl}/metadata/brand`, {
      isBlocking: blockingRequest !== false,
      params: new HttpParams({
        fromObject: pickBy({
          search: opts.search,
          skip: opts.skip && opts.skip.toString(),
          take: opts.take && opts.take.toString(),
          ids: opts.ids
        })
      })
    });
  }

  getColors(opts: GetMetadataRequest, blockingRequest: boolean): Observable<any> {
    return this.dataService.get(`${this.productUrl}/metadata/color`, {
      isBlocking: blockingRequest !== false,
      params: new HttpParams({
        fromObject: pickBy({
          search: opts.search,
          skip: opts.skip && opts.skip.toString(),
          take: opts.take && opts.take.toString()
        })
      })
    });
  }

  getHistory(productId: number, isStockCost: boolean, priceType: number, currency: string,
    priceListId: number, page: number, itemsPerPage: number): Observable<any> {
    return this.dataService.get(`${this.productUrl}/price-history/${productId}`, {
      params: new HttpParams({
        fromObject: pickBy({
          currency,
          isStockCost,
          priceListId,
          priceType,
          page,
          itemsPerPage
        })
      })
    });
  }

  getExchangeRates(fromCurrency: string, toCurrency: string): Observable<any> {
    let url = `${this.currencyUrl}/exchange-rates${(fromCurrency != null || toCurrency != null ? '?' : '')}`;

    if (fromCurrency != null) {
      url = url.concat(`from=${fromCurrency}${(toCurrency != null ? '&' : '')}`);
    }

    if (toCurrency != null) {
      url = url.concat(`to=${toCurrency}`);
    }

    return this.dataService.get(url);
  }

  getFilters(): Observable<any> {
    // TODO: Get filters from a shared endpoint
    return this.dataService.get(`purchase-order/filters`);
  }

  getGender(): Observable<any> {
    return this.dataService.get(`${this.productUrl}/gender`);
  }

  getProductIdentifiers(productId: string): Observable<any> {
    return this.dataService.get(`${this.productIdentifierUrl}/ProductIdentifiers/${productId}`);
  }

  getProductIdentifiersForList(productIds: number[] | string[]): Observable<any[]> {
    return this.dataService.get(`${this.productIdentifierUrl}/ProductIdentifiers`, {
      params: new HttpParams({
        fromObject: pickBy({
          ids: productIds.join(',')
        })
      })
    });
  }

  getPos(): Observable<any> {
    return this.dataService.get(`${this.productUrl}/pos`);
  }

  getPricelists(): Observable<GetPricelistsResponse> {
    return this.dataService.get(`${this.productUrl}/pricelists`);
  }

  getProduct(productId: string): Observable<any> {
    return this.dataService.get(`${this.productUrl}/${productId}`);
  }

  getProducts(productIds: number[] | string[]): Observable<any[]> {
    return this.dataService.get(`${this.productUrl}/list`, {
      params: new HttpParams({
        fromObject: pickBy({
          ids: productIds.join(',')
        })
      })
    });
  }

  getProductGroups(opts: GetMetadataRequest, blockingRequest: boolean): Observable<any> {
    return this.dataService.get(`${this.productUrl}/metadata/product-group`, {
      isBlocking: blockingRequest !== false,
      params: new HttpParams({
        fromObject: pickBy({
          search: opts.search,
          skip: opts.skip && opts.skip.toString(),
          take: opts.take && opts.take.toString()
        })
      })
    });
  }

  getProductPrices(request: string): Observable<any> {
    return this.dataService.get(`${this.productV2Url}/prices`, {
      params: new HttpParams({
        fromObject: pickBy({
          request
        })
      })
    });
  }

  getProductsPage(opts: GetProductsRequest): Observable<any> {
    return this.dataService.get(this.productUrl, {
      params: new HttpParams({
        fromObject: pickBy({
          itemsPerPage: opts.itemsPerPage && opts.itemsPerPage.toString(),
          page: opts.page && opts.page.toString(),
          search: opts.search
        })
      })
    });
  }

  getProductsCount(): Observable<any> {
    return this.dataService.get(`${this.productUrl}/count`, {
      isBlocking: false
    });
  }

  getProductsFields(): Observable<ProductFields> {
    return this.dataService.get(`${this.productUrl}/metadata`);
  }

  getProductSizes(productId: string): Observable<SizeRange[]> {
    return this.dataService.get(`${this.productV2Url}/${productId}/sizes`);
  }

  getProductSizesForOrder(productId: string, sizeId: number): Promise<any> {
    // TODO: Get rid of promises and logic here. Take a look at Currency
    return this.dataService.get(`${this.productV2Url}/sizes`, {
      params: new HttpParams({
        fromObject: pickBy({
          productId,
          sizeId: sizeId && sizeId.toString()
        })
      })
    }).toPromise()
      .catch(() => ({}))
      .then((response: any) => response);
  }

  getReportFilter(opts: GetMetadataRequest): Observable<any> {
    return this.dataService.get(`${this.productUrl}/report-filter`, {
      params: new HttpParams({
        fromObject: pickBy({
          search: opts.search,
          skip: opts.skip && opts.skip.toString(),
          take: opts.take && opts.take.toString()
        })
      })
    });
  }

  getSeasons(opts: GetMetadataRequest, blockingRequest: boolean): Observable<any> {
    return this.dataService.get(`${this.productUrl}/metadata/season`, {
      isBlocking: blockingRequest !== false,
      params: new HttpParams({
        fromObject: pickBy({
          search: opts.search,
          skip: opts.skip && opts.skip.toString(),
          take: opts.take && opts.take.toString()
        })
      })
    });
  }

  getSizes(opts: GetMetadataRequest, blockingRequest: boolean): Observable<any> {
    return this.dataService.get(`${this.productUrl}/metadata/size`, {
      isBlocking: blockingRequest !== false,
      params: new HttpParams({
        fromObject: pickBy({
          search: opts.search,
          skip: opts.skip && opts.skip.toString(),
          take: opts.take && opts.take.toString(),
          filterSingleProduct: opts.filterSingleProduct && opts.filterSingleProduct.toString()
        })
      })
    });
  }

  getMetadataStocks(opts: GetMetadataRequest, blockingRequest: boolean): Observable<any> {
    return this.dataService.get(`${this.productUrl}/metadata/stock`, {
      isBlocking: blockingRequest !== false,
      params: new HttpParams({
        fromObject: pickBy({
          search: opts.search,
          skip: opts.skip && opts.skip.toString(),
          take: opts.take && opts.take.toString(),
        })
      })
    });
  }

  getMetadataStockMovementTypeFilters(opts: GetMetadataRequest, blockingRequest: boolean): Observable<StockMovementTypeFilter[]> {
    return this.dataService.get(`${this.productUrl}/metadata/stock-movement-type`, {
      isBlocking: blockingRequest !== false,
      params: new HttpParams({
        fromObject: pickBy({
          search: opts.search,
          skip: opts.skip && opts.skip.toString(),
          take: opts.take && opts.take.toString(),
        })
      })
    });
  }

  getStockMovementByProductId(productId: string): Observable<any> {
    return this.dataService.get(`${this.productUrl}/stockMovement/${productId}`);
  }

  getStocks(): Observable<any> {
    return this.dataService.get(`${this.productUrl}/stocks`);
  }

  getStockCurrency(): Observable<any> {
    return this.dataService.get(`${this.currencyUrl}/stock`);
  }

  getStockShelfs(): Observable<any> {
    // TODO: Move StockMovementDataService to product or to a new module
    // (circle dependency product <-> po)
    return this.dataService.get('stock-movement/stockshelf');
  }

  deleteStockShelf(id: number): Observable<any> {
    return this.dataService.delete(`stock-movement/stockshelf/${id}`);
  }

  getStocksInventory(productId: string, includeCurrentStock: boolean = false, blockingRequest: boolean): Observable<any> {
    return this.dataService.get(`${this.productUrl}/stocksInventory/${productId}`, {
      isBlocking: blockingRequest !== false,
      params: new HttpParams({
        fromObject: pickBy({
          includeCurrentStock: includeCurrentStock.toString()
        })
      })
    });
  }

  getStores(): Observable<any> {
    return this.dataService.get(`${this.productUrl}/stores`);
  }

  getTags(): Observable<any> {
    return this.dataService.get(`${this.productUrl}/tags`);
  }

  getTagsFilter(opts: GetMetadataRequest, blockingRequest: boolean = false): Observable<any> {
    return this.dataService.get(`${this.productUrl}/tags-filter`, {
      isBlocking: blockingRequest !== false,
      params: new HttpParams({
        fromObject: pickBy({
          search: opts.search,
          skip: opts.skip && opts.skip.toString(),
          take: opts.take && opts.take.toString()
        })
      })
    });
  }

  getUnprintedSerials(productId: string): Observable<any> {
    return this.dataService.get(`${this.productUrl}/unprintedSerials/${productId}`);
  }

  loadHistory(productId: string, opts: GetProductHistoryRequest): Observable<any> {
    const filters = opts.filters;

    return this.dataService.get(`${this.productUrl}/history/${productId}`, {
      params: new HttpParams({
        fromObject: {
          page: opts.page && opts.page.toString(),
          itemsPerPage: opts.itemsPerPage && opts.itemsPerPage.toString(),
          stockMovTypeId: filters && filters.type.value?.id || '',
          sizeLabel: filters && (filters.size.value != null ? filters.size.value.label : null) || '',
          stockId: filters && filters.stock.id || '',
          user: filters && filters.user.value || '',
          date: filters && filters.date && filters.date.toISOString() || '',
          stockMovType: filters && filters.type.value?.name || ''
        }
      })
    });
  }

  getProductHistoryUsersFilter(productId: string): Observable<string[]> {
    return this.dataService.get(`${this.productUrl}/history/${productId}/users`);
  }

  loadReservations(productId: string, opts: GetProductReservationsRequest): Observable<any> {
    const filters = opts.filters;

    return this.dataService.get(`${this.productUrl}/reservations/${productId}`, {
      params: new HttpParams({
        fromObject: {
          page: opts.page && opts.page.toString(),
          itemsPerPage: opts.itemsPerPage && opts.itemsPerPage.toString(),
          sizeLabel: filters && (filters.size.value != null ? filters.size.value.label : null) || '',
          stockId: filters && filters.stock.id || '',
          user: filters && filters.user.value || '',
          date: filters && filters.date && filters.date.toISOString() || ''
        }
      })
    });
  }

  saveStockShelf(stockShelf: any): Observable<any> {
    // TODO: Move StockMovementDataService to product or to a new module
    // (circle dependency product <-> po)
    return this.dataService.post(`stock-movement/stockshelf`, stockShelf);
  }

  searchProducts(opts: GetProductsRequest): Observable<any> {
    return this.dataService.get(this.productSearchUrl, {
      params: new HttpParams({
        fromObject: pickBy({
          itemsPerPage: opts.itemsPerPage && opts.itemsPerPage.toString(),
          page: opts.page && opts.page.toString(),
          search: encodeURIComponent(opts.search)
        })
      })
    });
  }

  searchProductsForOrder(query: string, brandId: number, seasonId: number): Promise<any> {
    // TODO: Get rid of promises and logic here. Take a look at Currency
    return this.dataService.get(`${this.productSearchUrl}/for-po`, {
      params: new HttpParams({
        fromObject: pickBy({
          brandId: brandId && brandId.toString(),
          query,
          seasonId: seasonId && seasonId.toString()
        })
      })
    }).toPromise()
      .catch(() => [])
      .then((response: any) => response);
  }

  undeleteProduct(productId: string): Observable<any> {
    return this.dataService.post(`${this.productUrl}/undelete/${productId}`, null);
  }

  updateProduct(product: any): Observable<any> {
    return this.dataService.post(`${this.productUrl}/${product.id}`, product);
  }

  validateGTIN(id: string): Observable<any> {
    return this.dataService.get(`${this.productIdentifierUrl}/ProductIdentifiersValidation/${id}`);
  }

  getInventoryForStock(productId: number, stockId: number): Observable<Inventory> {
    return this.dataService.get(`${this.productV2Url}/inventory/${productId}/stock/${stockId}`);
  }

  getCollectionsFilter(search: string, page: number, pageSize: number): Observable<any> {
    return this.dataService.get(`${this.productV2Url}/collections/filter`, {
      params: new HttpParams({
        fromObject: pickBy({
          search,
          page,
          pageSize
        })
      })
    });
  }

  getCollectionsForProduct(productId: string): Observable<any> {
    return this.dataService.get(`${this.productSearchUrl}/collections/`, {
      params: new HttpParams({
        fromObject: pickBy({
          productId
        })
      })
    });
  }

  getSizeLabelsFilter(): Observable<any> {
    return this.dataService.get(`${this.productUrl}/metadata/sizelabels/`);
  }

  getCompanyCurrencies(): Observable<any> {
    return this.dataService.get(`${this.currencyUrl}/company`)
  }

  hasSpecificSizeSystems(): Observable<boolean> {
    return this.dataService.get(`${this.productV2Url}/hasSpecificSizeSystems`);
  }
}
